import * as statusMapper from "@/service/error_request_mapper.js";

export class CivilitiesService {
  constructor(apis) {
    this.apis = apis;

    this.api = this.apis.getCivilitiesApi();
  }

  async getAll() {
    return this.api
      .getAll()
      .then((data) => {
        data.sort(function(a, b) {
          return a.label.localeCompare(b.label);
        });
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  async getById(civilityId) {
    return this.api.getById(civilityId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  async create(civility) {
    return this.api.create(civility).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(civility.id);
      converter.add409AlreadyExists(civility.label);
      converter.convert(error);
    });
  }

  async update(civility) {
    return this.api.update(civility).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(civility.id);
      converter.convert(error);
    });
  }

  async delete(civilityId) {
    return this.api.delete(civilityId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(civilityId);
      converter.convert(error);
    });
  }
}
